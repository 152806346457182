export const SMP_CHANGE_SHIFT_CALENDAR = 'SMP_CHANGE_SHIFT_CALENDAR';
export const SMP_CHANGE_SCHEDULE_CALENDAR = 'SMP_CHANGE_SCHEDULE_CALENDAR';
export const SMP_RESET = 'SMP_RESET';
export const SMP_SELECT_CALENDARS = 'SMP_SELECT_CALENDARS';
export const SMP_ASSISTANT_REMOVE = 'SMP_ASSISTANT_REMOVE';
export const SMP_UNSUBSCRIBE_DATA_POINT = 'SMP_UNSUBSCRIBE_DATA_POINT';
export const SMP_SYNC_FAILED = 'SMP_SYNC_FAILED';

export const MAINTENANCE_ASSIGN = 'MAINTENANCE_ASSIGN';

export const CP_NEW_CALENDAR = 'CP_NEW_CALENDAR';
export const CP_CALENDAR_REMOVE = 'CP_CALENDAR_REMOVE';
export const CP_CALENDAR_CLONE = 'CP_CALENDAR_CLONE';
export const CP_CALENDAR_EDIT = 'CP_CALENDAR_EDIT';
export const CP_EVENT_REMOVE = 'CP_EVENT_REMOVE';
export const CP_EVENT_EDIT = 'CP_EVENT_EDIT';

export const DP_ASSISTANT_REMOVE = 'DP_ASSISTANT_REMOVE';
export const DP_ADD_DEVICE = 'DP_ADD_DEVICE';
export const DP_EDIT_DEVICE = 'DP_EDIT_DEVICE';
export const DP_ARCHIVE_DATA_POINT = 'DP_ARCHIVE_DATA_POINT';
export const DP_UNARCHIVE_DATA_POINT = 'DP_UNARCHIVE_DATA_POINT';
export const DP_ARCHIVE_DEVICE = 'DP_ARCHIVE_DEVICE';
export const DP_ADD_DATA_POINT = 'DP_ADD_DATA_POINT';
export const DP_EDIT_DATA_POINT = 'DP_EDIT_DATA_POINT';
export const DP_EDIT_DATA_POINT_INDEX = 'DP_EDIT_DATA_POINT_INDEX';
export const DP_VIEW_DATA_POINT = 'DP_VIEW_DATA_POINT';
export const DP_IMPORT_ACTION = 'DP_IMPORT_DEVICE';
export const DP_QUICK_COMMAND = 'DP_QUICK_COMMAND';
export const DP_COMMAND_HISTORY = 'DP_COMMAND_HISTORY';
export const DP_DOWNLOAD_FILE = 'DP_DOWNLOAD_FILE';
export const DP_UPLOAD_FILE = 'DP_UPLOAD_FILE';

export const ASSISTANT_ADD_NEW = 'ASSISTANT_ADD_NEW';
export const CONFIG_REPO_EXPLORER = 'CONFIG_REPO_EXPLORER';

export const REPORT_DUPLICATE = 'REPORT_DUPLICATE';
export const REPORT_REMOVE = 'REPORT_REMOVE';
export const REPORT_LEGEND_DATA_POINT = 'REPORT_LEGEND_DATA_POINT';
export const REPORT_EDIT = 'REPORT_EDIT';
export const REPORT_ADD = 'REPORT_ADD';

export const REPORTS_TAB_ADD = 'REPORTS_TAB_ADD';
export const REPORTS_TAB_EDIT = 'REPORTS_TAB_EDIT';
export const REPORTS_TAB_REMOVE = 'REPORTS_TAB_REMOVE';

export const TOOL_ADD = 'TOOL_ADD';
export const TOOL_EDIT = 'TOOL_EDIT';
export const TOOL_DETAILS = 'TOOL_DETAILS';

export const EXPERIMENT_ADD = 'EXPERIMENT_ADD';
export const EXPERIMENT_EDIT = 'EXPERIMENT_EDIT';
export const EXPERIMENT_ARCHIVE = 'EXPERIMENT_ARCHIVE';
export const EXPERIMENT_DOWNLOAD = 'EXPERIMENT_DOWNLOAD';
export const EXPERIMENT_DOWNLOAD_LINKS = 'EXPERIMENT_DOWNLOAD_LINKS';

export const MEASUREMENT_MERGE = 'MEASUREMENT_MERGE';
export const MEASUREMENT_ARCHIVE = 'MEASUREMENT_ARCHIVE';
export const MEASUREMENT_DOWNLOAD = 'MEASUREMENT_DOWNLOAD';

export const AP_ORGANIZATION_REMOVE = 'AP_ORGANIZATION_REMOVE';
export const AP_LOCATION_REMOVE = 'AP_LOCATION_REMOVE';
export const AP_USER_REMOVE = 'AP_USER_REMOVE';

export const LOCATION_PICKER = 'LOCATION_PICKER';

export const CONFIRMATION = 'CONFIRMATION';

// Page Modal IDs
// eslint-disable-next-line @typescript-eslint/naming-convention
export const _PAGE_REPORT_FORM = '_PAGE_REPORT_FORM';
